<template>
  <div class="body">
    <div class="top_card">
      <svg class="shaixuan1" aria-hidden="true" @click="isFilterBtn">
        <use xlink:href="#icon-logo_left_top"></use>
      </svg>
    </div>
    <!-- 插画没法替换 -->
    <div class="second_card">
      <img src="~@/views/helpHandbook/images/img2.png" alt="" />
    </div>
    <div class="container_card">
      <div class="container_card_top_title_wrapper">
        <i class="left"></i>
        <div class="title">用户使用手册</div>
        <i class="right"></i>
      </div>
      <!-- 主体 -->
      <div class="container_card_body_wrapper">
        <div
          class="container_card_body_item"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="wrapper_inner">
            <img :src="item.icon" alt="" />
            <div class="container_card_body_item_rigth">
              <div style="color: #333333">{{ item.title }}</div>
              <div style="color: #9195a5; margin-top: 12px">
                {{ item.desc }}
              </div>
              <div class="line"></div>
              <div
                v-for="(itemx, index2) in item.fileList"
                :key="index2"
                class="file_item"
              >
              <!-- download -->
                <a style="color: #006dcc"  :href="itemx.url">
                  {{ itemx.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 帮助手册
 */
export default {
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
  },
  data() {
    return {
      //阴影图片无法替换
      listData: [
        {
          title: "销售云",
          desc: "快速了解Lighting版本CloudCC",
          icon: require("@/views/helpHandbook/images/icon1.svg"),
          fileList: [
            {
              name: "销售云帮助手册.pdf",
              url:"./cloudcc-pdf/LightningPC销售云操作手册.pdf",
            },
            {
              name: "CPQ帮助手册.pdf",
              url: "./cloudcc-pdf/CloudCC 销售云CPQ帮助手册.pdf",
            },
          ],
        },
        {
          title: "服务云",
          desc: "完备的企业服务管理解决方案",
          icon: require("@/views/helpHandbook/images/icon2.svg"),
          fileList: [
            {
              name: "现场服务云帮助手册.pdf",
              url: "./cloudcc-pdf/服务云帮助手册.pdf",
            },
          ],
        },
        {
          title: "伙伴云",
          desc: "渠道订货与伙伴连接解决方案",
          icon: require("@/views/helpHandbook/images/icon3.svg"),
          fileList: [
            {
              name: "伙伴云上游企业帮助手册.pdf",
              url: "./cloudcc-pdf/伙伴云上游企业帮助手册.pdf"
            },
            {
              name: "伙伴云伙伴用户帮助手册.pdf",
              url: "./cloudcc-pdf/伙伴云伙伴用户帮助手册.pdf",
            },
          ],
        },
        {
          title: "项目云",
          desc: "瀑布式项目管理工具",
          icon: require("@/views/helpHandbook/images/icon4.svg"),
          fileList: [
            {
              name: "项目云帮助手册.pdf",
              url: "./cloudcc-pdf/项目云帮助手册.pdf",
            },
          ],
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.body {
  min-width: 1366px;
}
.top_card {
  height: 60px;
  display: flex;
  align-items: center;
  > svg {
    width: 183px;
    height: 44px;
    margin-left: 94px;
  }
}
.second_card {
  width: 100%;
  > img {
    width: 100%;
    min-width: 1366px;
  }
}
.container_card {
  display: flex;
  padding: 0 200px;
  flex-direction: column;
  .container_card_top_title_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 34px;
    > .left {
      width: 120px;
      height: 2px;
      transform: scaleX(-1);
      background-image: linear-gradient(
        234deg,
        rgba(0, 166, 232, 0) 33%,
        #006dcc 100%
      );
    }
    > .title {
      font-size: 28px;
      color: #333333;
      font-weight: 700;
      padding: 0 10px;
    }
    > .right {
      width: 120px;
      height: 2px;
      background-image: linear-gradient(
        234deg,
        rgba(0, 166, 232, 0) 33%,
        #006dcc 100%
      );
    }
  }

  .container_card_body_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 45px;

    .container_card_body_item {
      display: flex;
      align-items: center;
      width: 50%;
      margin-bottom: 80px;
      justify-content: center;
      .wrapper_inner {
        display: flex;
        width: 50%;
        img {
          width: 110px;
          height: 110px;
          margin-right: 30px;
        }
        .container_card_body_item_rigth {
          > div:first-child {
            font-size: 18px;
            font-weight: 600;
          }
          .line {
            width: 240px;
            height: 1px;
            margin: 8px 0;
            background-image: linear-gradient(
              131deg,
              #dedcda 0%,
              rgba(222, 220, 218, 0) 100%
            );
          }
          .file_item {
            margin-top: 8px;
            font-family: SourceHanSansCN-Normal;
            font-size: 16px;
            color: #006dcc;
            letter-spacing: 0;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>